<template>
  <div class="addPerson__wrap" v-show="visible">
    <el-button class="backBtn" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <div style="display:flex;font-size:16px;font-weight: bold;margin:10px 0">
        <div style="margin-right:30px">用工单位：{{company_name}}</div>
        <div>考勤日期：{{day}}</div>
      </div>
      <el-input style="width:220px;margin-top:10px;margin-right:20px;" v-model.trim="params.name" size="mini" placeholder="请输入姓名">
       <i slot="suffix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-input style="width:220px;margin-top:10px;" v-model.trim="params.phone" size="mini" placeholder="请输入电话">
       <i slot="suffix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-button size="mini" type="primary" @click="getList" style="margin-left:10px">查询</el-button>
      <el-button size="mini" @click="empty">重置</el-button>
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%;margin-top:10px;margin-left:20px;"
      :header-cell-style="{background:'#f5f5f5'}">
      <el-table-column
        type="index"
        label="ID"
        width="80">
      </el-table-column>
      <el-table-column
        prop="employee_name"
        label="员工姓名"
        width="120px">
      </el-table-column>
      <el-table-column
        prop="employee_phone"
        label="联系电话"
        width="120">
      </el-table-column>
      <el-table-column
        prop="start_time"
        label="上班打卡时间"
        width="120">
        <template slot-scope="{row}">
          <span>{{row.start_time}}</span>
          <el-button type="text" :class="`color${row.start_status}`" style="margin-left:10px;" @click="handleRectify(row,1)">{{toStr({0:'未打卡',1:'正常卡',2:'迟到',3:'早退',4:'缺卡'},row.start_status)}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="start_image"
        label="上班打卡图片"
        width="110px">
        <template slot-scope="{row}">
          <el-image
            v-if="row.start_image"
            style="width: 50px; height: 50px"
            :src="row.start_image"
            fit="cover"
            :preview-src-list="[row.start_image]"></el-image>
          </template>
      </el-table-column>
      <el-table-column
        prop="start_address"
        label="上班打卡地址"
        :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column
        prop="end_time"
        label="下班打卡时间"
        width="120">
        <template slot-scope="{row}">
          <span>{{row.end_time}}</span>
          <el-button type="text" :class="`color${row.end_status}`" style="margin-left:10px;" @click="handleRectify(row,2)">{{toStr({0:'未打卡',1:'正常卡',2:'迟到',3:'早退',4:'缺卡'},row.end_status)}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="end_image"
        label="下班打卡图片"
        width="110px">
        <template slot-scope="{row}">
          <el-image
            v-if="row.end_image"
            style="width: 50px; height: 50px"
            :src="row.end_image"
            fit="cover"
            :preview-src-list="[row.end_image]"></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="end_address"
        label="下班打卡地址"
        :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column
        prop="clock_count"
        label="打卡次数"
        width="120px">
        <template slot-scope="{row}">
          {{row.clock_count}}次
        </template>
      </el-table-column>
    </el-table>
    <div class='pagination__wrap' style="margin:20px;" v-if='total > 6'>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="params.page"
        :page-size="params.count"
        :total="total"
        :page-sizes="[10, 20, 50, 100, 150]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="`${currRow.employee_name}  （${toStr({0:'未打卡',1:'正常卡',2:'迟到',3:'早退',4:'缺卡'},currRow.start_status)}）`"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      append-to-body>
      <span style="margin-bottom:10px;">您要处理：</span>
      <el-radio-group v-model="sel_status" size="mini">
        <el-radio :label="1" border>正常打卡</el-radio>
        <el-radio v-if="currRow.start_status == 4 && currType == 1" :label="2" border>迟到</el-radio>
        <el-radio v-if="currRow.end_status == 4 && currType == 2" :label="3" border>早退</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import { getStorage } from '@/storage'
  import { REG_EXP } from '@/util'
  export default {
    name: 'RecordEdit',
    data() {
      return {
        token: getStorage('token'),
        icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司）
        role_type: getStorage('role_type'), // 1:管理员，2：员工
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        company_name: '',
        day: '',
        params:{
          currId:'',
          name:'',
          phone:'',
          page:1,
          count:10
        },
        total:0,
        tableData: [],
        dialogVisible: false,
        currRow: {},
        sel_status: 1, //1-正常卡；2-迟到；3-早退；
        currType: 1, //打卡类型：1-上班卡；2-下班卡
      }
    },
    methods: {
      // 详情
      getDetail(row) {
        this.isChange = true
        this.params.currId = row.id;
        this.getList()
      },
      getList() {
        let _params = {
          id: this.params.currId,
          name: this.params.name,
          phone: this.params.phone,
          page: this.params.page,
          count: this.params.count
        }
        this.$http.get('/admin/attendance/detail', {params:_params}).then(res => {
          if(res.code === 1) {
            this.company_name = res.data.company_name;
            this.day = res.data.day;
            this.tableData = res.data.list;
            this.total = res.data.total;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      empty() {
        this.params.name = '';
        this.params.phone = '';
        this.params.page = '';
        this.params.count = 10;
        this.params.page = 1;
        this.getList();
      },
      reset(done) {
        this.isChange = false
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$confirm('请确认处理?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let _params = {
            id: this.currRow.id,
            status: this.sel_status,
            type: this.currType, //打卡类型：1-上班卡；2-下班卡
          }
          this.$http.post('/admin/attendance/deal',_params).then(res => {
            if(res.code == 1) {
              this.dialogVisible = false;
              this.$message.success('操作成功！')
              this.getList();
            }
          })
        }).catch(() => {});
      },
      // 每页多少条数据
      handleSizeChange(pageSize) {
        this.params.count = pageSize;
        this.getList();
      },
      // 当前在第几页
      handleCurrentChange(page) {
        this.params.page = page;
        this.getList();
      },
      // 处理上班卡打卡状态：0-未打卡；1-正常卡；2-迟到；3-早退；4-缺卡；
      handleRectify(row,num) {
        this.dialogVisible = true;
        this.currRow = row;
        this.currType = num; //打卡类型：1-上班卡；2-下班卡
      },
      handleClose() {
        this.dialogVisible = false;
      },
      toStr(data,key) {
        return data[key]
      },
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 10px;
  z-index: 5 !important;
  overflow: scroll;
  
  .main {
    margin: 0 auto;
    padding:0 20px;
    // background: #fbfbfb;

    .staff-from {
      width: 80%;
      margin: 0 auto;
    }
  }
  .color4{
    color: #f56c6c;
  }
}

</style>
