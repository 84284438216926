<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-row>
          <el-col :span="24">
            <el-form-item label="年份筛选" prop="year">
              <el-date-picker
                v-model="table.params.year"
                type="year"
                placeholder="选择年"
                format="yyyy年"
                value-format="yyyy">
              </el-date-picker>
              <span
                :class="table.params.month == v ? 'monthV acColor' : 'monthV' "
                v-for="v in this.monthObj"
                :key="v"
                @click="selMonth(v)"
              >{{v}}月</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="用工单位" prop="company_id">
              <el-select v-model="table.params.company_id" placeholder="请选择" filterable >
                <el-option
                  v-for="item in companyOpt"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开票单位" prop="invoice_company_id" v-if="icId == 0">
              <el-select v-model="table.params.invoice_company_id" placeholder="请选择" clearable filterable>
                <el-option
                  v-for="item in invoiceCompanyOpt"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="考勤日期" prop="time">
              <el-date-picker
                v-model="table.params.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearch">查询
              </el-button>
              <el-button @click="reset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" plain @click="openExport">导出考勤</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="icId == 0 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:manager_fee_type="{row}">
        <span>{{managerFeeType[row.manager_fee_type]}}</span>
      </template>
      <template v-slot:settlement_type="{row}">
        <span>{{settlementType[row.settlement_type]}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showDetail(row)">查看</el-button>
      </template>
    </VTable>


    <el-dialog
      append-to-body
      width="500px"
      title="导出考勤"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        v-loading="loading"
        element-loading-text="加载中…"
        ref="elFormDom"
        label-width="100px"
        label-position="right"
        :model="form.data"
        :rules="form.rules"
        :size="subUnitSize"
      >
        <el-form-item prop="name" label="导出月份：">
          <span>{{this.table.params.month}}月</span>
        </el-form-item>
        <el-form-item prop="company_id" label="用工单位：">
          <el-select
            v-model="form.data.company_id"
            placeholder="请选择所属单位"
            clearable
            filterable
            style="width:100%;"
          >
            <el-option
              v-for="item in companyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="end">
          <el-button @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            :loading="form.loading"
            :disabled="form.loading"
            @click="handleExport"
            >确定</el-button
          >
        </el-row>
      </template>
    </el-dialog>

    <detail ref="detail"></detail>
    
  </div>
</template>

<script>
import Detail from './components/Detail.vue'
import VTable from '@/components/VTable';
import {exportData} from '@/util'
import {getStorage} from '@/storage'
import { mapGetters } from 'vuex'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: '',
  mixins:[mixinTable],
  components: {
    Detail,
    VTable
  },
  computed: {
    ...mapGetters({
      invoiceCompanyOpt: 'invoiceCompany',
      companyOpt: 'companyList', // 获取企业列表
    })
  },
  data() {
    return {
      icId: Number(getStorage('icId')), 
      token: getStorage('token'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      monthObj:['01','02','03','04','05','06','07','08','09','10','11','12'],
      field: [
        { name: "id", label: "ID", width:"60"},
        { name: "company_name", label: "用工单位", hidden: false },
        { name: "invoice_company_name", label: "开票单位", hidden: false },
        { name: "day", label: "考勤日期", hidden: false },
        { name: "onwork_total", label: "在职人数", hidden: false},
        { name: "clock_total", label: "打卡人数", hidden: false},
        { name: "lack_total", label: "缺卡人次", hidden: false},
        { name: "late_total", label: "迟到人次", hidden: false},
        { name: "leave_total", label: "早退人次", hidden: false},
        { name: "created_at", label: "统计时间", width:"160", hidden: false},
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      field2: [
        { name: "id", label: "ID", width:"60"},
        { name: "company_name", label: "用工单位", hidden: false },
        { name: "day", label: "考勤日期", hidden: false },
        { name: "onwork_total", label: "在职人数", hidden: false},
        { name: "clock_total", label: "打卡人数", hidden: false},
        { name: "lack_total", label: "缺卡人次", hidden: false},
        { name: "late_total", label: "迟到人次", hidden: false},
        { name: "leave_total", label: "早退人次", hidden: false},
        { name: "created_at", label: "统计时间", width:"160", hidden: false},
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      loading: false,
      table: {
        loading: false,
        params: {
          ids: [],
          year:'',
          month: '',
          start_day:'',
          end_day: '',
          invoice_company_id: '',
          company_id: '',
          time:[],
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      managerFeeType: {
        1:'企业',
        2:'员工'
      },
      settlementType: {
        1:'当月薪资',
        2:'上月薪资'
      },
      rowId: '',
      isDetail: false,

      dialogVisible: false,
      fileList: [],
      upload: {
        company_id: '',
        file: '',
      },
      curfile: '',
      // 导入后提示
      successDialogVisible: false, // 导入成功弹窗
      errorDialogVisible: false,  // 导入异常弹窗
      templateDialog: {
        skip_errors: '',
        result: '',
        errors: [],
      },
      innerVisible: false,
      danwei:'',

      form: {
        loading: false,
        data: {
          company_id: '',
        },
        rules: {
          company_id: [{required: true, message: "请选择用工单位！", trigger: "change"}],
        }
      },
    }
  },
  created() {
    // 默认当前年月
    this.table.params.year = this.$moment().format("yyyy");
    this.table.params.month = this.monthObj[this.$moment().format("MM") - 1]
    this.getTable();
  },
  methods: {
    getTable() {
      let _time = this.table.params.time;
      let _params = {...this.table.params}
      if(_time.length > 0) {
        _params.start_day = _time ?  _time[0] : ''
        _params.end_day = _time ? _time[1] : ''
      }
      delete _params.time
      delete _params.ids
      this.$http.get('/admin/attendance/companyList', {params:_params}).then(res => {
        if(res.code == 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    openExport() {
      // 勾选了ID（单选）
      if(this.table.params.ids.length > 0) {
        let _params = {
          ids: this.table.params.ids.toString(),
        }
        exportData(_params,'/admin/attendance/export')
      } else {
        this.dialogVisible = true;
      }
    },
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 批量选择
    handleSelectionChange(batchData) {
      this.table.params.ids = [];
      if(batchData.length > 0) {
        batchData.forEach(v => {
          this.table.params.ids.push(v.id)
        })
      }
    },
    // 全部导出
    handleExport() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params = {
            year: this.table.params.year, // 当未传id时必传
            month: this.table.params.month, // 当未传id时必传
            company_id: this.form.data.company_id, // 当未传id时必传
          }
          exportData(_params,'/admin/attendance/export')
          this.dialogVisible = false;
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    selMonth(v) {
      this.table.params.month = v;
      this.getTable();
    }
  }
}
</script>
<style scoped>
.uploadTitle {
  color: #db4242;
  font-weight: bold;
  text-align: center;
}
.uploadContent {
  min-height: 300px;
}
.errorBox {
  max-height: 450px;
  overflow-y: scroll;
}
.monthV {
  margin-left: 16px;
  cursor: pointer;
  font-weight: bold;
  color: #595959;
}
.acColor {
  color: #409eff;
}
</style>